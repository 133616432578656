<template>
  <v-expansion-panels
    data-testid="hub-profile-master-data-contact-container"
    class="shadow-regular"
    :value="open"
    flat
  >
    <v-expansion-panel class="rounded-lg pa-2">
      <v-expansion-panel-header class="heading-h3">
        <div class="d-flex align-center">
          <span
            data-testid="hub-profile-master-data-contact-title"
            class="mr-2"
          >
            {{ t('Title_.subTitle.contact') }}
          </span>
        </div>
        <template #actions>
          <v-icon
            data-testid="hub-profile-master-data-contact-chevron-icon"
            color="#212121"
          >
            mdi-chevron-down
          </v-icon>
        </template>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-form
          data-testid="hub-profile-master-data-contact-form"
          ref="form"
          v-model="isFormValid"
          class="mt-4"
        >
          <v-container class="pa-0">
            <v-row dense>
              <v-col cols="12">
                <RegularTextInput
                  data-testid="hub-profile-master-data-contact-number-field"
                  :label="t('Msg_.hubNumber') + '*'"
                  :disabled="isFormDisabled"
                  v-model="hubContactInfo.hubNumber"
                  :rules="[
                    rules.fieldRequired(
                      `${t('Msg_.hubNumber')} ${t('Msg_.rules.isRequired')}`
                    ),
                    rules.totalSize(4, t('Msg_.rules.mustHaveFourDigits')),
                    rules.onlyNumbers(t('Msg_.rules.onlyNumbers')),
                  ]"
                />
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12">
                <RegularTextInput
                  data-testid="hub-profile-master-data-contact-name-field"
                  :label="t('Msg_.hubName') + '*'"
                  :disabled="isFormDisabled"
                  v-model="hubContactInfo.name"
                  :rules="[
                    rules.fieldRequired(
                      `${t('Msg_.hubName')} ${t('Msg_.rules.isRequired')}`
                    ),
                    rules.valueSize(
                      30,
                      `${t('Msg_.hubName')} ${t(
                        'Msg_.rules.fieldMustHaveLessThan'
                      )} 30 ${t('Msg_.rules.characters')}`
                    ),
                  ]"
                />
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12">
                <RegularTextInput
                  data-testid="hub-profile-master-data-contact-noventi-name-field"
                  :label="t('Msg_.noventiHubName') + '*'"
                  :disabled="isFormDisabled"
                  v-model="hubContactInfo.noventiName"
                  :rules="[
                    rules.fieldRequired(
                      `${t('Msg_.noventiHubName')} ${t(
                        'Msg_.rules.isRequired'
                      )}`
                    ),
                    rules.valueSize(
                      15,
                      `${t('Msg_.noventiHubName')} ${t(
                        'Msg_.rules.fieldMustHaveLessThan'
                      )} 15 ${t('Msg_.rules.characters')}`
                    ),
                  ]"
                />
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12">
                <RegularTextInput
                  data-testid="hub-profile-master-data-contact-email-field"
                  :label="t('Msg_.email')"
                  :disabled="isFormDisabled"
                  v-model="hubContactInfo.email"
                />
              </v-col>
            </v-row>
            <v-row dense class="d-flex justify-space-between">
              <v-col cols="6">
                <RegularTextInput
                  class="pr-3"
                  data-testid="hub-profile-master-data-contact-fax-number-field"
                  :label="t('Msg_.faxNumber')"
                  :disabled="isFormDisabled"
                  v-model="hubContactInfo.faxNumber"
                />
              </v-col>
              <v-col cols="6">
                <RegularTextInput
                  class="pl-3"
                  data-testid="hub-profile-master-data-contact-phone-number-field"
                  :label="t('Msg_.phoneNumber')"
                  :disabled="isFormDisabled"
                  v-model="hubContactInfo.phoneNumber"
                />
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12">
                <RegularTextInput
                  data-testid="hub-profile-master-data-contact-county-field"
                  :label="t('Msg_.county') + '*'"
                  :disabled="isFormDisabled"
                  v-model="hubContactInfo.county"
                  :rules="[
                    rules.fieldRequired(
                      `${t('Msg_.county')} ${t('Msg_.rules.isRequired')}`
                    ),
                    rules.valueSize(
                      100,
                      `${t('Msg_.hubName')} ${t(
                        'Msg_.rules.fieldMustHaveLessThan'
                      )} 100 ${t('Msg_.rules.characters')}`
                    ),
                  ]"
                />
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12">
                <DropdownInput
                  data-testid="hub-profile-master-data-contact-state-dropdown"
                  :label="t('Msg_.state') + '*'"
                  :items="selectStateType"
                  :disabled="isFormDisabled"
                  v-model="hubContactInfo.state"
                  :rules="[
                    rules.fieldRequired(
                      `${t('Msg_.state')} ${t('Msg_.rules.isRequired')}`
                    ),
                  ]"
                />
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12">
                <DropdownInput
                  data-testid="hub-profile-master-data-contact-region-dropdown"
                  :label="t('Msg_.region') + '*'"
                  :items="selectRegionType"
                  :disabled="isFormDisabled"
                  v-model="hubContactInfo.region"
                  :rules="[
                    rules.fieldRequired(
                      `${t('Msg_.region')} ${t('Msg_.rules.isRequired')}`
                    ),
                  ]"
                />
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12">
                <DropdownInput
                  data-testid="hub-profile-master-data-contact-team-planner-dropdown"
                  :label="t('Msg_.mainResponsibleTeamPlanner')"
                  :items="selectHubMainResponsibleTeamPlanners"
                  :disabled="isFormDisabled"
                  v-model="hubContactInfo.mainResponsibleTeamPlannerId"
                />
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12">
                <DropdownInput
                  data-testid="hub-profile-master-data-contact-coach-dropdown"
                  :label="t('Msg_.coach')"
                  :items="selectHubCoaches"
                  :disabled="isFormDisabled"
                  v-model="hubContactInfo.coachId"
                />
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12">
                <DropdownInput
                  data-testid="hub-profile-master-data-growth-responsible-dropdown"
                  :label="t('Msg_.growthResponsible')"
                  :items="selectGrowthResponsibles"
                  :disabled="isFormDisabled"
                  v-model="hubContactInfo.growthResponsibleId"
                />
              </v-col>
            </v-row>
          </v-container>
          <v-container
            v-if="canUser(['Hubs.UpdateMasterContact'])"
            class="px-0 pt-6 pb-2"
          >
            <v-row>
              <v-col v-if="isFormDisabled" cols="12" class="d-flex justify-end">
                <v-btn
                  data-testid="hub-profile-master-data-contact-edit-info-btn"
                  class="main-action-btn"
                  @click="toggleDisabled()"
                >
                  {{ t('Action_.buttons.editInformation') }}
                </v-btn>
              </v-col>
              <v-col
                v-if="!isFormDisabled"
                cols="12"
                class="d-flex justify-end"
              >
                <v-btn
                  data-testid="hub-profile-master-data-contact-cancel-btn"
                  class="main-action-variant-btn"
                  @click="displayConfirmationDialog()"
                >
                  {{ t('Action_.buttons.cancel') }}
                </v-btn>
                <v-btn
                  data-testid="hub-profile-master-data-contact-save-changes-btn"
                  class="main-action-btn ml-2"
                  :disabled="!isFormValid"
                  @click="updateHubContactInfoById()"
                >
                  {{ t('Action_.buttons.saveChanges') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script setup>
import RegularTextInput from '@/components/shared/customComponents/inputs/regular-text-input.vue';
import DropdownInput from '@/components/shared/customComponents/inputs/dropdown-input.vue';

import rules from '@/helpers/rules';
import helpers from '@/helpers/helpers';
import languages from '../../locales/languages';
import { useI18n } from 'vue-i18n-bridge';
import { usePermissions } from '@/helpers/composables/permissions.js';
import { useRoute, onBeforeRouteLeave } from 'vue-router/composables';
import { useHubsStore } from '@/store/pinia/hubs.store.js';
import { usePeopleStore } from '@/store/pinia/people.store.js';
import { usePopupDialogStore } from '@/store/pinia/popup-dialog.store.js';
import { ref, computed, onBeforeUnmount } from 'vue';

const form = ref();
const route = useRoute();
const hubsStore = useHubsStore();
const peopleStore = usePeopleStore();
const popupDialogStore = usePopupDialogStore();
const { canUser } = usePermissions();
const { t, tm } = useI18n({ locale: 'en', messages: languages });

// --- Local variables ---
const open = ref(0);
const isFormValid = ref(false);
const isFormDisabled = ref(true);
const unchangedForm = ref(null);
const nextRoute = ref(null);
const hubContactInfo = ref({
  id: '',
  hubNumber: '',
  name: '',
  noventiName: '',
  email: '',
  faxNumber: '',
  phoneNumber: '',
  county: '',
  state: null,
  region: null,
  mainResponsibleTeamPlannerId: null,
  mainResponsibleTeamPlannerName: null,
  coachId: null,
  coachName: null,
  growthResponsibleId: null,
  growthResponsibleName: null,
});

// --- Methods ---
const getHubContactInfoById = async () => {
  try {
    hubContactInfo.value = await hubsStore.getHubContactInfoById(
      route.params.hubId
    );
    helpers.convertNullToEmptyStringInObject(hubContactInfo.value);
    form.value.resetValidation();
  } catch (error) {
    console.log(error);
  }
};

const getHubMenuInformation = async () => {
  try {
    await hubsStore.getHubInfoById(route.params.hubId);
  } catch (error) {
    console.log(error);
  }
};

const updateHubContactInfoById = async () => {
  try {
    const payload = {
      hubId: route.params.hubId,
      hubContactInfo: hubContactInfo.value,
    };
    await hubsStore.updateHubContactInfoById(payload);
    await getHubMenuInformation();
    await getHubContactInfoById();
    assignFormData();
  } catch (error) {
    console.log(error);
  }
  toggleDisabled();
};

const toggleDiscardChangesDialog = (next) => {
  const actionBtn = () => {
    discardChanges();
    if (nextRoute.value) next();
  };

  const params = {
    type: 'confirmation',
    title: t('Action_.dialog.saveChanges.title'),
    message: t('Action_.dialog.saveChanges.body'),
    actionBtnText: t('Action_.buttons.proceed'),
    actionBtn: actionBtn,
  };

  popupDialogStore.params = params;
  popupDialogStore.displayDialog = true;
};

const isFormAltered = () => {
  if (unchangedForm.value) {
    return !helpers.compareTwoObjects(
      hubContactInfo.value,
      unchangedForm.value
    );
  }
  return false;
};

const assignFormData = () => {
  unchangedForm.value = { ...{}, ...hubContactInfo.value };
};

const clearNextRouteData = () => {
  nextRoute.value = null;
};

const discardChanges = () => {
  hubContactInfo.value = unchangedForm.value;
  toggleDisabled();
};

const toggleDisabled = () => {
  assignFormData();
  form.value.validate();
  isFormDisabled.value = !isFormDisabled.value;
};

const displayConfirmationDialog = () => {
  clearNextRouteData();
  if (isFormAltered()) {
    toggleDiscardChangesDialog();
  } else {
    toggleDisabled();
  }
};

// --- Computed ---
const selectStateType = computed(() => {
  const onlyStates = peopleStore.peopleResources?.stateTypes.filter(
    ({ Value }) => Value !== 0
  );

  return helpers.translateAndAdaptToVuetify(
    tm('Action_.selectStateTypes'),
    onlyStates
  );
});

const selectRegionType = computed(() => {
  return helpers.translateAndAdaptToVuetify(
    tm('Action_.selectRegionTypes'),
    peopleStore.peopleResources?.regionTypes
  );
});

const selectHubMainResponsibleTeamPlanners = computed(() => {
  const hubMainResponsibleTeamPlanners =
    hubsStore.hubResponsibleEmployees?.mainResponsibleTeamPlanners.map(
      (coach) => {
        return { value: coach.id, text: coach.name };
      }
    );
  return hubMainResponsibleTeamPlanners;
});

const selectHubCoaches = computed(() => {
  const hubCoaches = hubsStore.hubResponsibleEmployees?.coaches.map((coach) => {
    return { value: coach.id, text: coach.name };
  });
  return hubCoaches;
});

const selectGrowthResponsibles = computed(() => {
  const hubGrowthResponsible =
    hubsStore.hubResponsibleEmployees?.growthResponsible.map((coach) => {
      return { value: coach.id, text: coach.name };
    });
  return hubGrowthResponsible;
});

// --- Lifecycle hooks ---
getHubContactInfoById();

onBeforeUnmount(() => {
  clearNextRouteData();
});

// --- Routing guard ---
onBeforeRouteLeave((to, _, next) => {
  clearNextRouteData();
  if (!isFormAltered()) {
    nextRoute.value = to;
  }
  if (nextRoute.value) {
    next();
  } else {
    nextRoute.value = to;
    toggleDiscardChangesDialog(next);
  }
});
</script>

<style scoped lang="scss"></style>
