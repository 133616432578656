<template>
  <v-expansion-panels
    data-testid="hub-profile-master-data-stats-container"
    class="shadow-regular"
    :value="open"
    flat
  >
    <v-expansion-panel class="rounded-lg pa-2">
      <v-expansion-panel-header class="heading-h3">
        <div class="d-flex align-center">
          <span data-testid="hub-profile-master-data-stats-title" class="mr-2">
            {{ t('Title_.subTitle.stats') }}
          </span>
          <v-tooltip top color="ashLight">
            <template #activator="{ on, attrs }">
              <v-icon
                data-testid="hub-profile-master-data-stats-info-icon"
                color="blueRegular"
                dark
                v-bind="attrs"
                v-on="on"
                small
              >
                mdi-information-outline
              </v-icon>
            </template>
            <div
              data-testid="hub-profile-master-data-stats-tooltip"
              class="d-flex flex-column pa-2"
            >
              <span class="tooltip-text body-text-3">
                {{ `${t('Msg_.employeeStatusIncluded')}:` }}
              </span>
              <div class="d-flex align-center">
                <v-icon color="#212121" dark small>
                  mdi-check-circle-outline
                </v-icon>
                <span class="tooltip-text body-text-4 ml-1">{{
                  `${t('Msg_.active')}, ${t('Msg_.offboarding')}`
                }}</span>
              </div>
              <div class="d-flex align-center">
                <v-icon color="#212121" dark small>
                  mdi-close-circle-outline
                </v-icon>
                <span class="tooltip-text body-text-4 ml-1">{{
                  `${t('Msg_.starter')}, ${t('Msg_.inactive')}, ${t(
                    'Msg_.exited'
                  )}`
                }}</span>
              </div>
            </div>
          </v-tooltip>
        </div>
        <template #actions>
          <v-icon
            data-testid="hub-profile-master-data-stats-chevron-icon"
            color="#212121"
          >
            mdi-chevron-down
          </v-icon>
        </template>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-container>
          <v-row class="element-border">
            <v-col
              data-testid="hub-profile-master-data-stats-total-employees"
              cols="9"
              class="px-0 body-text-4"
            >
              {{ t('Msg_.totalNumberOfEmployees') }}
            </v-col>
            <v-col
              data-testid="hub-profile-master-data-stats-total-employees-value"
              cols="3"
              class="px-0 text-right heading-h4"
            >
              {{ hubStats.totalNumberOfEmployees }}
            </v-col>
          </v-row>
          <v-row class="element-border">
            <v-col
              data-testid="hub-profile-master-data-stats-total-hours"
              cols="9"
              class="px-0 body-text-4"
            >
              {{ t('Msg_.totalHoursPerWeek') }}
            </v-col>
            <v-col
              data-testid="hub-profile-master-data-stats-total-hours-value"
              cols="3"
              class="px-0 text-right heading-h4"
            >
              {{
                `${hubStats.totalHoursPerWeek}${t('Msg_.hoursAbbreviation')}`
              }}
            </v-col>
          </v-row>
        </v-container>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script setup>
import languages from '../../locales/languages';
import { useI18n } from 'vue-i18n-bridge';
import { useRoute } from 'vue-router/composables';
import { useHubsStore } from '@/store/pinia/hubs.store.js';
import { ref } from 'vue';

const route = useRoute();
const hubsStore = useHubsStore();
const { t } = useI18n({ locale: 'en', messages: languages });

// --- Local variables ---
const open = ref(0);
const hubStats = ref({
  totalNumberOfEmployees: null,
  totalHoursPerWeek: null,
});

// --- Methods ---
const getHubStatsById = async () => {
  try {
    hubStats.value = await hubsStore.getHubStatsById(route.params.hubId);
  } catch (error) {
    console.log(error);
  }
};

// --- Lifecycle hooks ---
getHubStatsById();
</script>

<style scoped lang="scss">
.element-border {
  border-top: 1px solid $ash-regular;
  border-radius: 4px;
}

.tooltip-text {
  color: #212121;
}
</style>
