<template>
  <v-expansion-panels
    data-testid="hub-profile-master-data-building-container"
    class="shadow-regular"
    :value="open"
    flat
  >
    <v-expansion-panel class="rounded-lg pa-2">
      <v-expansion-panel-header>
        <div>
          <span
            data-testid="hub-profile-master-data-building-title"
            class="heading-h3 mr-2"
          >
            {{ t('Title_.subTitle.building') }}
          </span>
          <div
            v-if="hubBuildingInfo.isCreatedOnNoventi"
            class="d-flex align-center mt-2"
          >
            <v-icon class="mr-1" color="caribbeanDark" dark small>
              mdi-check-circle-outline
            </v-icon>
            <span
              data-testid="hub-profile-master-data-building-created-in-noventi"
              class="caption-2"
            >
              {{ t('Msg_.hubIsCreatedInNoventi') }}
            </span>
          </div>
          <div v-else class="d-flex align-center mt-2">
            <v-icon
              data-testid="hub-profile-master-data-building-info-icon"
              class="mr-1"
              color="greyDark"
              small
            >
              mdi-information-outline
            </v-icon>
            <span
              data-testid="hub-profile-master-data-building-add-address-in-noventi"
              class="caption-2"
            >
              {{ t('Msg_.addAddressToNoventi') }}
            </span>
          </div>
        </div>
        <template #actions>
          <v-icon
            data-testid="hub-profile-master-data-building-chevron-icon"
            color="#212121"
          >
            mdi-chevron-down
          </v-icon>
        </template>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-form
          data-testid="hub-profile-master-data-building-form"
          ref="form"
          v-model="isFormValid"
          class="mt-4"
        >
          <v-container class="pa-0">
            <v-row dense>
              <v-col cols="12">
                <RegularTextInput
                  data-testid="hub-profile-master-data-building-street-field"
                  :label="t('Msg_.street') + '*'"
                  :disabled="isFormDisabled"
                  v-model="hubBuildingInfo.street"
                  :rules="[
                    rules.fieldRequired(
                      `${t('Msg_.street')} ${t('Msg_.rules.isRequired')}`
                    ),
                    rules.valueSize(
                      100,
                      `${t('Msg_.street')} ${t(
                        'Msg_.rules.fieldMustHaveLessThan'
                      )} 100 ${t('Msg_.rules.characters')}`
                    ),
                  ]"
                />
              </v-col>
            </v-row>
            <v-row dense class="d-flex justify-space-between">
              <v-col cols="6">
                <RegularTextInput
                  data-testid="hub-profile-master-data-building-house-number-field"
                  class="pr-3"
                  :label="t('Msg_.houseNumber') + '*'"
                  :disabled="isFormDisabled"
                  v-model="hubBuildingInfo.houseNumber"
                  :rules="[
                    rules.fieldRequired(
                      `${t('Msg_.houseNumber')} ${t('Msg_.rules.isRequired')}`
                    ),
                    rules.valueSize(
                      10,
                      `${t('Msg_.houseNumber')} ${t(
                        'Msg_.rules.fieldMustHaveLessThan'
                      )} 10 ${t('Msg_.rules.characters')}`
                    ),
                  ]"
                />
              </v-col>
              <v-col cols="6">
                <DropdownInput
                  data-testid="hub-profile-master-data-building-floor-dropdown"
                  class="pl-3"
                  :label="t('Msg_.floor') + '*'"
                  :clearable="false"
                  :items="selectHubFloorTypes"
                  :disabled="isFormDisabled"
                  v-model="hubBuildingInfo.floorType"
                  :rules="[
                    rules.fieldRequired(
                      `${t('Msg_.floor')} ${t('Msg_.rules.isRequired')}`,
                      0
                    ),
                  ]"
                />
              </v-col>
            </v-row>
            <v-row dense class="d-flex justify-space-between">
              <v-col cols="6">
                <RegularTextInput
                  class="pr-3"
                  data-testid="hub-profile-master-data-building-zip-code-field"
                  :label="t('Msg_.zipCode') + '*'"
                  :disabled="isFormDisabled"
                  v-model="hubBuildingInfo.zipCode"
                  :rules="[
                    rules.fieldRequired(
                      `${t('Msg_.zipCode')} ${t('Msg_.rules.isRequired')}`
                    ),
                    rules.totalSize(5, t('Msg_.rules.mustHaveFiveDigits')),
                    rules.onlyNumbers(t('Msg_.rules.onlyNumbers')),
                  ]"
                />
              </v-col>
              <v-col cols="6">
                <RegularTextInput
                  class="pl-3"
                  data-testid="hub-profile-master-data-building-city-field"
                  :label="t('Msg_.city') + '*'"
                  :disabled="isFormDisabled"
                  v-model="hubBuildingInfo.city"
                  :rules="[
                    rules.fieldRequired(
                      `${t('Msg_.city')} ${t('Msg_.rules.isRequired')}`
                    ),
                    rules.valueSize(
                      100,
                      `${t('Msg_.city')} ${t(
                        'Msg_.rules.fieldMustHaveLessThan'
                      )} 100 ${t('Msg_.rules.characters')}`
                    ),
                  ]"
                />
              </v-col>
            </v-row>
          </v-container>
          <v-container
            v-if="canUser(['Hubs.UpdateMasterBuilding'])"
            class="px-0 pt-6 pb-2"
          >
            <v-row>
              <v-col v-if="isFormDisabled" cols="12" class="d-flex justify-end">
                <v-btn
                  data-testid="hub-profile-master-data-building-edit-info-btn"
                  class="main-action-btn"
                  @click="toggleDisabled()"
                >
                  {{ t('Action_.buttons.editInformation') }}
                </v-btn>
              </v-col>
              <v-col
                v-if="!isFormDisabled"
                cols="12"
                class="d-flex justify-end"
              >
                <v-btn
                  data-testid="hub-profile-master-data-building-cancel-btn"
                  class="main-action-variant-btn"
                  @click="displayConfirmationDialog()"
                >
                  {{ t('Action_.buttons.cancel') }}
                </v-btn>
                <v-btn
                  data-testid="hub-profile-master-data-building-save-changes-btn"
                  class="main-action-btn ml-2"
                  :disabled="!isFormValid"
                  @click="handleBuildingDataUpdate()"
                >
                  {{ t('Action_.buttons.saveChanges') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script setup>
import RegularTextInput from '@/components/shared/customComponents/inputs/regular-text-input.vue';
import DropdownInput from '@/components/shared/customComponents/inputs/dropdown-input.vue';

import rules from '@/helpers/rules';
import helpers from '@/helpers/helpers';
import languages from '../../locales/languages';
import { useI18n } from 'vue-i18n-bridge';
import { usePermissions } from '@/helpers/composables/permissions.js';
import { useRoute, onBeforeRouteLeave } from 'vue-router/composables';
import { useHubsStore } from '@/store/pinia/hubs.store.js';
import { usePeopleStore } from '@/store/pinia/people.store.js';
import { usePopupDialogStore } from '@/store/pinia/popup-dialog.store.js';
import { ref, computed, onBeforeUnmount } from 'vue';

const form = ref();
const route = useRoute();
const hubsStore = useHubsStore();
const peopleStore = usePeopleStore();
const popupDialogStore = usePopupDialogStore();
const { canUser } = usePermissions();
const { t } = useI18n({ locale: 'en', messages: languages });

// --- Local variables ---
const open = ref(0);
const isFormValid = ref(false);
const isFormDisabled = ref(true);
const unchangedForm = ref(null);
const nextRoute = ref(null);
const hubBuildingInfo = ref({
  street: '',
  houseNumber: '',
  floorType: null,
  zipCode: '',
  city: '',
  isCreatedOnNoventi: false,
});

// --- Methods ---
const getHubBuildingInfoById = async () => {
  try {
    hubBuildingInfo.value = await hubsStore.getHubBuildingInfoById(
      route.params.hubId
    );
    helpers.convertNullToEmptyStringInObject(hubBuildingInfo.value);
    form.value.resetValidation();
  } catch (error) {
    console.log(error);
  }
};

const updateHubBuildingInfoById = async () => {
  try {
    const payload = {
      hubId: route.params.hubId,
      hubBuildingInfo: hubBuildingInfo.value,
    };
    await hubsStore.updateHubBuildingInfoById(payload);
    await getHubBuildingInfoById();
    assignFormData();
  } catch (error) {
    console.log(error);
  }
  toggleDisabled();
};

const toggleDiscardChangesDialog = (next) => {
  const actionBtn = () => {
    discardChanges();
    if (nextRoute.value) next();
  };

  const params = {
    type: 'confirmation',
    title: t('Action_.dialog.saveChanges.title'),
    message: t('Action_.dialog.saveChanges.body'),
    actionBtnText: t('Action_.buttons.proceed'),
    actionBtn: actionBtn,
  };

  popupDialogStore.params = params;
  popupDialogStore.displayDialog = true;
};

const toggleCreateHubInNoventiDialog = () => {
  const params = {
    title: t('Action_.dialog.createHubInNoventi.title'),
    message: t('Action_.dialog.createHubInNoventi.body'),
    reinforcement: t('Action_.dialog.createHubInNoventi.reinforcement'),
    actionBtnText: t('Action_.buttons.confirm'),
    actionBtn: updateHubBuildingInfoById,
  };

  popupDialogStore.params = params;
  popupDialogStore.displayDialog = true;
};

const handleBuildingDataUpdate = () => {
  if (hubBuildingInfo.value.isCreatedOnNoventi) {
    updateHubBuildingInfoById();
  } else {
    toggleCreateHubInNoventiDialog();
  }
};

const isFormAltered = () => {
  if (unchangedForm.value) {
    return !helpers.compareTwoObjects(
      hubBuildingInfo.value,
      unchangedForm.value
    );
  }
  return false;
};

const assignFormData = () => {
  unchangedForm.value = { ...{}, ...hubBuildingInfo.value };
};

const clearNextRouteData = () => {
  nextRoute.value = null;
};

const discardChanges = () => {
  hubBuildingInfo.value = unchangedForm.value;
  toggleDisabled();
};

const toggleDisabled = () => {
  assignFormData();
  form.value.validate();
  isFormDisabled.value = !isFormDisabled.value;
};

const displayConfirmationDialog = () => {
  clearNextRouteData();
  if (isFormAltered()) {
    toggleDiscardChangesDialog();
  } else {
    toggleDisabled();
  }
};

// --- Computed ---
const selectHubFloorTypes = computed(() => {
  const hubFloor = peopleStore.peopleResources?.floorTypes.map((floor) => {
    return { value: floor.Value, text: floor.Name };
  });
  return hubFloor;
});

// --- Lifecycle hooks ---
getHubBuildingInfoById();

onBeforeUnmount(() => {
  clearNextRouteData();
});

// --- Routing guard ---
onBeforeRouteLeave((to, _, next) => {
  clearNextRouteData();
  if (!isFormAltered()) {
    nextRoute.value = to;
  }
  if (nextRoute.value) {
    next();
  } else {
    nextRoute.value = to;
    toggleDiscardChangesDialog(next);
  }
});
</script>

<style scoped lang="scss"></style>
