<template>
  <div>
    <HubProfileMasterDataStats
      v-if="canUser(['Hubs.ReadMasterStats'])"
      class="mb-8"
    />
    <HubProfileMasterDataContract
      v-if="canUser(['Hubs.ReadMasterContact'])"
      class="mb-8"
    />
    <HubProfileMasterDataBuilding v-if="canUser(['Hubs.ReadMasterBuilding'])" />
  </div>
</template>

<script setup>
import HubProfileMasterDataStats from './hub-profile-master-data-stats.vue';
import HubProfileMasterDataContract from './hub-profile-master-data-contact.vue';
import HubProfileMasterDataBuilding from './hub-profile-master-data-building.vue';
import { usePermissions } from '@/helpers/composables/permissions.js';

const { canUser } = usePermissions();
</script>
